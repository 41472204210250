export * from './IUser';
export * from './ILogin';
export * from './iToken';
export * from './ITicket';
export * from './INotification';
export * from './IAlert';
export * from './IColors';
export * from './IStateAlert';
export * from './ILocation';
export * from './IPointMap';
export * from './ITheme';