export namespace VandalismRoundReport {
  export type Params = {
    directory: string;
  }

  export type Round = {
    routeId: string;
    routeDate: string;
    origin: string;
    stopPoints: number;
    destination: string;
    directory: string;
    score: 0;
  }

  export type Response = Array<Round>
}
