export namespace VandalismTicketPopup {
  export interface ImagePopupParams {
    ticketId: number;
    isFirstImage: boolean;
  }

  export interface TicketPopupParams {
    ticketId: number;
    lat: number;
    lng: number
  } 

  export interface ReportImagePopupParams {
    ticketId: number,
    imageIndex: number,
    validation: boolean
  }

  export interface DisplayDataPopup {
    isLegacy: string,
    ticketId: number,
    taskIdIncident: string,
    ticketSystemOrigin: string,
    technicianId: string,
    address: string,
    reportedDate: string,
    routeDate: string,
    cause: string,
    operational: string,
    status: string,
    quantity: number,
    isPead: boolean
  }
}
