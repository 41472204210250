import { Role } from '../enums/Role';
import { Permission } from '../enums/Permission';
import { ICompany } from './ICompany';

export interface IUser {
  id: number;
  username: string;
  fullName: string;
  state: string;
  directors: string;
  role: Role;
  permissions: Permission[];
  email: string;
  lastSignIn: string;
  company: ICompany;
  policy: boolean;
}
